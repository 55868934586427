.loader-container {
    display: flex;
    position: fixed;
    width: 100%;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #96549630;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-image: url("https://cdn.dribbble.com/users/137715/screenshots/3984718/loading.gif");
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



.image-wrapper {
    position: relative;
    display: inline-block;
}

.image-wrapper::before {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 120px;
    height: 120px;
    border: 5px solid transparent;
    border-top-color: rgb(155, 76, 245);
    /* loader color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
    /* spinning animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
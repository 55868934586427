:root {
    --main-color: #846cf9;
    --main-color-100: #e8ebf6;
    --main-color-200: #d7d5ff;
    --main-color-300: #b9b4fe;
    --main-color-400: #9689fc;
    --main-color-600: #6237f0;
    --main-color-700: #5325dc;
    --green-color: #89B72B;
    --orange-color: #F8B01D;
    --black-color: #000000;
    --red-color: #ff6347;
    --white-color: #ffffff;
}

html {
    scroll-behavior: smooth;
}

.scrollable-tabs {
    display: flex;
    overflow-x: auto;
    /* Enable horizontal scrolling */
    white-space: nowrap;
    /* Prevent tabs from wrapping to the next line */
    padding: 0;
    /* Optional: remove padding */
}

.scrollable-tabs::-webkit-scrollbar {
    height: 8px;
    /* Set the height of the scrollbar */
}

.scrollable-tabs::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Color of the scrollbar */
    border-radius: 4px;
    /* Round corners of the scrollbar */
}

.scrollable-tabs::-webkit-scrollbar-thumb:hover {
    background-color: #555;
    /* Color on hover */
}

/* Media query to apply scrollable class on small devices */
@media (max-width: 768px) {
    .scrollable-tabs {
        overflow-x: auto;
        /* Enable horizontal scrolling */
        overflow-y: hidden;
        /* Hide vertical overflow */
    }
}

.NavSection.css-0 {
    background-color: #7e67b9 !important;
}

.cc-1 {
    background-color: #cab8ff;
    color: black;
}

.cc-2 {
    background-color: #b5deff;
    color: black;
}

.cc-3 {
    background-color: #c1ffd7;
    color: black;
}

.cc-4 {
    background-color: #fcffa6;
    color: black;
}

.cc-5 {
    background-color: #81f5ff;
    color: black;
}

.cc-6 {
    background-color: #a0ffe6;
    color: black;
}

.cc-7 {
    background-color: #ffffdd;
    color: black;
}

.cc-8 {
    background-color: #ffd5e5;
    color: black;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.h-350 {
    height: 350px;
    overflow: auto;
}

.h-calc {
    height: calc(100vh - 200px)
}

.cursor-pointer {
    cursor: pointer !important;
}

.table-hover:hover {
    background-color: var(--main-color-100);
    transition: background-color 0.5s ease;
}

.main-col-new {
    background-color: var(--main-color) !important;
    color: white !important;
}

.cl-green {
    background-color: var(--green-color) !important;
}

.cl-orange {
    background-color: var(--orange-color) !important;
}

.text-white-light {
    color: #f5f5ff !important;
}

.btn-orange {
    background-color: var(--orange-color) !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.text-orange {
    color: var(--orange-color);
}

.text-green {
    color: var(--green-color);
}

.btn-danger {
    background-color: var(--red-color) !important;
    color: var(--white-color) !important;
    margin: 0px 15px !important;
}

.btn-green {
    background-color: var(--main-color) !important;
    color: var(--white-color) !important;
    margin: 0px 15px;
}

.btn-green-disable {
    background-color: var(--main-color-200) !important;
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.btn-linear {
    background: rgb(137, 183, 43);
    background: linear-gradient(261deg, rgba(137, 183, 43, 1) 0%, rgba(248, 176, 29, 1) 100%);
    color: var(--black-color) !important;
    margin: 0px 15px !important;
}

.swal2-container {
    z-index: 9999 !important;
}

.h-500 {
    height: 500px;

}

.MuiTable-root th,
.MuiTable-root tr {
    white-space: nowrap;
}

/* Styles for the entire scrollbar */
body::-webkit-scrollbar,
body::-webkit-scrollbar-horizontal,
.MuiTableContainer-root::-webkit-scrollbar,
.MuiTableContainer-root::-webkit-scrollbar-horizontal {
    width: 10px;
    height: 10px;

    background-color: #f5f5f5;
}

/* Styles for the scrollbar track */
body::-webkit-scrollbar-track,
body::-webkit-scrollbar-horizontal-track,
.MuiTableContainer-root::-webkit-scrollbar-track,
.MuiTableContainer-root::-webkit-scrollbar-horizontal-track {
    -webkit-box-shadow: inset 0 0 6px rgba(188, 188, 188, 0.3);
    background-color: #f5f5f5;
    width: 12px;
}

/* Styles for the scrollbar thumb */
body::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-horizontal-thumb,
.MuiTableContainer-root::-webkit-scrollbar-thumb,
.MuiTableContainer-root::-webkit-scrollbar-horizontal-thumb {
    background-color: #afafaf;
    border: 1px solid #ffffff;
}

/* Notification */

.Notification-section {
    position: absolute;
    background: #fcfcfc;
    width: 350px;
    right: -86px;
    top: 140%;
    border-radius: 5px;
    overflow: hidden;
    z-index: 999;

}

.Notification-section .title {
    background-color: var(--main-color-400);
    padding: 15px 0px;
    color: white;
}

.Notification-section .message {
    font-size: 13px;
    background-color: #919eab1f;
    border-bottom: 1px solid #d3cff1;
    color: black;
}

.Notification-section .timing {
    font-size: 12px;
    color: #6d6d6d;
}

.Notification-section ul {
    height: 300px;
    overflow-y: scroll;
}

.Notification-section .end-title {
    color: black;
    padding: 6px 0px;
    background: #edeff1;
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

/* Notification */


/* LoginPage */
.design-login {
    height: 400px;
    position: absolute;
    top: 0px;
    left: -5%;
    width: 10%;
    z-index: 999;
    background-color: #f9fafb;
    border-radius: 10px;
}

/* LoginPage */

body {
    background: #eaedf7 !important;
}

.shape1 {
    position: absolute;
    top: -30px;
    width: 20px;
    height: 60px;
    inset-inline-end: 0px;
    background: #eaedf7;
}

.shape2 {
    position: absolute;
    top: 35px;
    width: 20px;
    height: 30px;
    inset-inline-end: 0px;
    background: #eaedf7;
}

.Active-title::before {
    content: "";
    position: absolute;
    top: -30px;
    z-index: 9;
    inset-inline-end: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 48px;
    border-bottom-left-radius: 0;
    border-right: 20px solid #7e67b9;
}

.Active-title:hover {
    color: black;
    background: var(--main-color-100);
    font-weight: bold;
    border-radius: 30px 0px 0px 30px;
}

.Active-title {
    color: black;
    background: var(--main-color-100);
    font-weight: bold;
    border-radius: 30px 0px 0px 30px;
}

.Active-title::after {
    content: "";
    position: absolute;
    top: 48px;
    z-index: 0;
    inset-inline-end: 0px;
    bottom: 0;
    height: 30px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 48px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-right: 20px solid #7e67b9;
}


/* Dashboard */
.welcome-sec {
    border-radius: 5px;
    background-color: var(--main-color);
}

.welcome-sec .box {
    background-color: #8f79fa;
    color: #f5f5ffcc;
    border-radius: 5px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, #0000);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, #0000);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, #0000);
}

.apexcharts-title-text {
    display: none !important;
}

/* Dashboard */

/*daterangepicker css*/

.rs-picker-daterange>.rs-input-group.rs-input-group-inside .rs-input {
    padding-top: 3%;
    padding-bottom: 3%;
}

.rs-input-group.rs-input-group-inside {
    margin-top: 6%;
}

.rs-input {
    font-size: 16px;
}

.css-1jsya2q {
    background: #7e67b9 !important;
    width: 280px !important;
}

.horror-bg-image {
    background-image: url(../components/logo/Hauntedhouse.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.accordion-box {
    border-radius: 10px !important;
    padding: 10px 0px;

}

.newclientdata p {
    font-weight: bold;
}

.animated-icon {
    width: 30px;
    height: 30px;
    border-radius: 5px;
}

.screensize-box {
    border: 1px solid #c4b7b7;
    border-radius: 10px;
    width: 143px;
    padding: 10px;
}



.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border: 2px dashed #ccc;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
}

.upload-container:hover {
    border-color: #007bff;
}

.upload-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #777;
    width: 100%;
    height: 100%;
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

#file-upload {
    display: none;
}

.swiper-button-prev,
.swiper-button-next {
    width: 25px !important;
    height: 25px !important;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;

}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 12px !important;
}

.plan-radio label .MuiButtonBase-root {
    display: none !important;
}

.limit-sec label {
    display: none !important;
}

.filter-btn-active {
    height: 36px !important;
    width: 36px !important;
    border-radius: 7px;
    background: black;
}

.excel-btn-active {
    height: 36px !important;
    width: 36px !important;
    border-radius: 7px;
    background: #46832f;
}

/* .MuiFormControl-marginNormal {
    margin-top: 0px !important;
} */

/* td,
th {
    padding: 15px !important;
} */


@media screen and (max-width: 768px) {
    .swiper-login {
        display: none !important;
    }

    /* td,
    th {
        padding: 13px !important;
    } */
}

@media screen and (max-width:575px) {

    th,
    tr,
    td {

        font-size: 12px !important;
    }
}

@media screen and (min-width:420px) {
    .d-sm-none {
        display: block !important;
    }
}

@media screen and (max-width:420px) {
    .fs-sm-14 {
        font-size: 14px !important;
    }

    .fs-sm-12 {
        font-size: 12px !important;
    }

    .d-sm-none {
        display: none !important;
    }
}